import { render, staticRenderFns } from "./FindPasswordSuccess.vue?vue&type=template&id=24c0850e&scoped=true&"
import script from "./FindPasswordSuccess.vue?vue&type=script&lang=js&"
export * from "./FindPasswordSuccess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c0850e",
  null
  
)

export default component.exports